<template>
  <div class="resize">
    <div class="l-col">
      <base-select
        v-model="formData.from"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="options"
        track-by="from"
        :custom-label="nameDiskFrom"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChange($event, 'source')"
      >
        <label class="typo__label standart-title"> {{ $t('label.disk') }}</label>
      </base-select>
    </div>
    <div class="l-col">
      <base-input
        v-model="formData.diskName"
        :placeholder="$t('disk.placeholder')"
        class="form__field--input"
        @input="onChange($event, 'name')"
      >
        <label class="typo__label standart-title"> {{ $t('disk.label') }}</label>
        <!--        @change="onChange($event, 'name')"-->
      </base-input>
    </div>

    <div v-if="formData.from.from === 'disk'" class="l-col">
      <base-select
        v-model="formData.volume"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        placeholder="Выберите диск"
        :options="disks"
        label="name"
        track-by="name"
        :custom-label="nameDisk"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange($event, 'disk')"
      >
        <label class="typo__label standart-title"> {{ $t('label.image') }}</label>
      </base-select>
    </div>
    <div
      v-if="(formData.from.from === 'file') & (formData.osName.from !== 'windows')"
      class="l-col"
    >
      <div class="l-col" style="padding-bottom: 0">
        <label class="typo__label standart-title">{{ $t('text_file_size') }}</label>
      </div>
      <div class="l-col">
        <label class="typo__label standart-title">{{ $t('text_file_size_up') }}</label>
      </div>
      <vue-dropzone
        id="dropzone"
        ref="Dropzone"
        class="dropzone"
        :duplicate-check="true"
        :include-styling="false"
        :use-custom-slot="true"
        :options="dropzoneOptions"
        @vdropzone-file-added="fileAdded"
        @vdropzone-success-multiple="false"
      >
        <div class="dropzone-container">
          <plain-button
            v-if="this.$store.getters['moduleStack/GET_FILES'].length < 1"
            color="primary"
            class="dropzone-container__file-link"
          >
            <div class="send-message__text">
              <svg-icon name="clip" class="send-message__icon" />{{ $t('add_file') }}
            </div>
          </plain-button>
        </div>
      </vue-dropzone>
      <AttachmentList
        class="send-message__container-dropzone__list"
        :temp-attachments="getTempAttachments"
        :address="'moduleStack'"
        :attachments="getAttachments"
        @remove-attachment="clearFormData()"
      />
    </div>
    <div v-if="formData.from.from === 'link'" class="l-col">
      <base-input
        v-model="formData.link"
        :placeholder="$t('disk.url')"
        class="form__field--input"
        @input="onChange(event, 'link')"
      >
        <label class="typo__label standart-title"> {{ $t('disk.link') }}</label>
      </base-input>
    </div>
    <div v-if="formData.from.from !== 'disk'" class="l-col">
      <base-select
        v-model="formData.osName"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="typeOs"
        track-by="from"
        :custom-label="nameDiskFrom"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChange($event, 'os')"
      >
        <label class="typo__label standart-title"> {{ $t('label.disk') }}</label>
      </base-select>
    </div>
    <div v-if="formData.from.from !== 'disk' && formData.osName.from !== 'windows'" class="l-col">
      <base-select
        v-model="formData.format"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        placeholder="Выберите тип"
        :options="formatOfImage"
        track-by="type"
        :custom-label="nameType"
        :preselect-first="true"
        size="medium"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange($event, 'type')"
      >
        <label class="typo__label standart-title"> {{ $t('label.type') }}</label>
      </base-select>
    </div>
    <div class="l-col" style="padding-bottom: 0">
      <div v-if="formData.osName.from !== 'windows'" class="l-col" style="padding-bottom: 0">
        <label class="typo__label standart-title">
          Для изменения пароля root-пользователя через личный кабинет и автоматической настройки в
          образе должны быть установлены Cloud-init и QEMU Guest Agent.
        </label>
      </div>
    </div>
    <div v-if="formData.osName.from === 'windows'" class="resize-column">
      <label class="typo__label standart-title"> {{ $t('windowsInfo') }}</label>
      <div class="resize-link">
        <Link
          class="link"
          :text="$t('cli')"
          link="https://support.rusonyx.ru/index.php?/Knowledgebase/List/Index/135/rbot-s-cli"
          :base="false"
        ></Link>
        <!--        class="resize-row"-->
        <Link
          class="link"
          :text="$t('winImage')"
          :base="false"
          link="https://support.rusonyx.ru/index.php?/Knowledgebase/Article/View/686/135/podkljuchenie-cli-v-windows"
        ></Link>
      </div>
    </div>
    <!--    <link :text="">-->
    <div
      v-if="formData.from.from !== 'disk' && formData.osName.from !== 'windows'"
      class="resize-row"
    >
      <div class="typo__label standart-title">{{ $t('disk.size') }}</div>
      <div class="resize-row-input">
        <InputNumber
          class="input-number"
          v-model="formData.sizeImage"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="formData.size > 5 ? +formData.size : 5"
          :max="quota"
          button-layout="horizontal"
          :input-style="{
            'text-align': 'center',
            'max-width': '10rem',
            'min-width': '5rem',
            width: '100%',
          }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange($event, 'minSize')"
        />
        <!--          @input="onChange('size', $event)"-->
      </div>
    </div>
    <div class="l-col" style="padding-bottom: 0; margin-bottom: -2rem">
      <div v-if="formData.osName.from !== 'windows'" class="l-col">
        <!--      {{ $t(disk.from === 'link' ? 'costLink' : 'cost', { msg: cost.toFixed(2) }) }}-->
        <label
          class="standart-text"
          v-html="
            $t(formData.from.from === 'link' ? 'costLink' : 'cost', {
              msg: $n(cost, 'currency', this.$i18n.locale),
            })
          "
        >
        </label>
        <!--      $n(cost, 'currency', this.$i18n.locale)-->
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import vue2Dropzone from 'vue2-dropzone';
import AttachmentList from '@/layouts/Support/component/AttachmentList';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import InputNumber from 'primevue/inputnumber';
import showErrorModal from '@/mixins/showErrorModal';
import Link from '@/components/Configurator/components/Link';
export default {
  name: 'NewImage',
  components: {
    InputNumber,
    BaseInput,
    AttachmentList,
    Link,
    BaseSelect,
    vueDropzone: vue2Dropzone,
  },
  mixins: [setFocus, showErrorModal],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 5,
      require: true,
    },
  },
  data() {
    return {
      tempAttachments: [],

      dropzoneOptions: {
        // url: 'https://my.rusonyx.ru/billmgr',
        url: `https://cloudapi.rusonyx.ru/image/v2/images/${this.id}/file`,
        method: 'put',
        maxFilesize: 20000,
        maxFiles: 1,
        autoProcessQueue: false,
        clickable: true,
        includeStyling: false,
        previewsContainer: false,
        uploadMultiple: false,
        headers: {
          'Content-Type': 'application/json',
        },
        parallelUploads: 1,
        // acceptedFiles: '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF,.pdf,.doc,.docx',
      },
      value: '',
      name: '',
      description: '',
      disk: [],
      type: [],
      volume: [],
      min_disk: 0,
      osName: [],
      image: [],
      formData: {
        diskName: '',
        from: { from: 'file', name: 'файл' },
        container_format: 'bare',
        visibility: 'private',
        disk_format: '',
        size: 5,
        sizeImage: 5,
        file: [],
        value: '',
        volume: [],
        osName: [],
        disk: [],
        type: [],
        format: { type: 'Raw', description: 'дефолтный тип' },
        image: [],
        min_disk: 5,
        link: '',
        name: '',
        id: '',
        // type: 'HDD',
        // source: '',
        func: '',
      },
    };
  },
  computed: {
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === 'image')[dev]
        : null;
    },

    cost() {
      if (this.formData.from.from === 'file' && this.price && this.tempAttachments.length > 0) {
        return (this.tempAttachments[0].size / 1024 ** 3).toPrecision(2) * this.price;
      } else if (
        this.formData.from.from === 'disk' &&
        this.price &&
        this.formData.volume &&
        this.formData.volume.size
      ) {
        return this.formData.volume.size * this.price;
      } else if (this.formData.from.from === 'link' && this.price) {
        return this.price;
      } else return 0;
    },
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    },
    options() {
      return [
        { from: 'file', name: 'файл' },
        { from: 'link', name: 'ссылка' },
        { from: 'disk', name: 'диск' },
      ];
    },
    typeOs() {
      return [
        {
          from: 'linux',
          name: 'Linux',
          hw_scsi_model: 'virtio-scsi',
          hw_qemu_guest_agent: 'yes',
          hw_disk_bus: 'scsi',
        },
        {
          from: 'windows',
          name: 'Windows',
          hw_scsi_model: 'virtio-scsi',
          hw_qemu_guest_agent: 'yes',
          hw_disk_bus: 'scsi',
          hw_firmware_type: 'uefi',
          hw_machine_type: 'q35',
        },
        {
          from: '',
          name: 'Другая',
          hw_scsi_model: 'virtio-scsi',
          hw_qemu_guest_agent: 'yes',
          hw_disk_bus: 'scsi',
        },
      ];
    },
    disks() {
      return this.$store.getters['moduleStack/disks']
        .filter(x => x.status.code === 15)
        .map(x => {
          return {
            name: x.name,
            id: x.id,
            size: x.size,
            func: 'source_volid',
          };
        });
    },
    sizeDisk() {
      return this.tempAttachments && this.tempAttachments.length === 0
        ? this.formData.size
        : Math.ceil(this.tempAttachments[0].size / 1024 ** 3) > this.formData.size
        ? Math.ceil(this.tempAttachments[0].size / 1024 ** 3)
        : this.formData.size;
    },
    formatOfImage() {
      return [
        { type: 'Raw', description: 'дефолтный тип' },
        { type: 'ISO', description: 'образ оптического диска' },
        { type: 'PLOOP', description: 'Virtuozzo/Parallels петлевой диск' },
        { type: 'QCOW2', description: 'образ QEMU' },
        { type: 'VDI', description: 'образ виртуального диска' },
        { type: 'VHD', description: 'виртуальный жесткий диск' },
        { type: 'VMDK', description: 'диск виртуальной машины' },
        { type: 'AKI', description: 'образ Amazon Kernel' },
        { type: 'AMI', description: 'образ Amazon Machine' },
        { type: 'ARI', description: 'образ Amazon Ramdisk' },
      ];
    },
    minSizeDisk() {
      return this.formData.from === 'none'
        ? this.sizeDisk
        : this.formData.size > this.sizeDisk // впихнуть отслеживание размера подгружаемого файла
        ? this.formData.size
        : this.sizeDisk;
    },
  },
  watch: {
    'tempAttachments.length': function (val) {
      if (this.formData.disk.from === 'file' && val === 0) {
        return this.$emit('notready');
      }
    },
    tempAttachments: {
      handler: function (event) {
        // console.log(event);
        this.formData.file = event;
      },
    },
    formData: {
      handler: function (oldVal, newVal) {
        // handler: function (val) {
        // console.log(newVal);
        if (this.formData.osName.from === 'windows') {
          this.$emit('notready');
        } else {
          if (this.formData.diskName.length === 0) {
            this.$emit('notready');
          } else {
            if (newVal.from.from === 'file') {
              this.$emit('change', newVal);
            } else if (newVal.from.from === 'disk') {
              this.$emit('change', newVal);
            } else if (newVal.from.from === 'link') {
              this.$emit('change', newVal);
            }
          }
        }
      },
      deep: true,
      immediate: false,
    },

    'formData.size': {
      handler: function (event) {
        if (event) this.formData.size = this.formData.size < this.sizeDisk ? this.sizeDisk : event;
      },
      immediate: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.dispatch('moduleStack/resetFiles');
  },
  methods: {
    clearFormData() {
      this.formData.disk_format = 'raw';
      this.formData.size = 5;
      this.formData.type = 'raw';
    },
    fileName(file) {
      const typeOfExt = this.formatOfImage.map(x => x.type.toLowerCase());
      const m = file.name.match(/\.([^.]+)$/);
      const ext = (m && m[1]) || 'raw';
      const name = file.name.substr(0, file.name.lastIndexOf('.')) || file.name;
      const extention = typeOfExt.filter(x => x === ext).toString() || 'raw';

      this.formData.format = this.formatOfImage.find(x => x.type.toLowerCase() === extention);
      if (!this.formData.diskName) this.formData.diskName = name;
      // this.formData.type = extention;
      this.formData.disk_format = extention.toLowerCase();
    },
    fileAdded(file) {
      this.fileName(file);

      let attachment = {};
      attachment.file = file;
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = 'file';
      attachment.extension = '.' + file.type.split('/')[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      this.formData.size = +Math.ceil(file.size / 1024 ** 3).toPrecision(2);
      this.formData.sizeImage =
        this.formData.size > this.formData.sizeImage
          ? +this.formData.size
          : this.formData.sizeImage;

      if (
        attachment.size < 10 * 1024 * 1024 * 1024 &&
        this.tempAttachments.length < 1 &&
        !this.tempAttachments.map(t => t.title).includes(attachment.title)
      ) {
        if (this.tempAttachments.length !== this.$store.state.moduleStack.files.length) {
          this.$store.dispatch('moduleStack/resetFiles');
        }
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.formData.name = this.formData.name ? this.formData.name : file.name;
        this.$store.dispatch('moduleStack/addFiles', file);
        if (this.formData.diskName && this.formData.size && this.formData.file) {
          // console.log('file_add file');
          return this.$emit('change', {
            name: this.formData.diskName,
            size:
              this.formData.sizeImage > this.formData.size
                ? this.formData.sizeImage
                : this.formData.size,
            min_size: this.formData.sizeImage,
            disk_format: this.formData.disk_format,
            file: this.tempAttachments[0],
            os_type: this.formData.os_type,
            hw_scsi_model: this.formData.hw_scsi_model,
            hw_qemu_guest_agent: this.formData.hw_qemu_guest_agent,
            hw_disk_bus: this.formData.hw_disk_bus,
            os_distro: this.formData.os_distro,
            hw_firmware_type: this.formData.hw_firmware_type,
            hw_machine_type: this.formData.hw_machine_type,
          });
        } else return this.$emit('notready');
      } else {
        // console.log('error');
        this.showError('Файл превышает размер 10ГБ');
      }
    },
    nameDisk({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameSnapshot({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameImage({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameDiskFrom({ name }) {
      return name.charAt(0).toUpperCase() + name.slice(1);

      // return `${name}`;
    },
    nameType({ type, description }) {
      return `${type} - ${description}`;
    },
    onChange(event, type) {
      // console.log(event, type);
      if (event && type === 'disk') {
        this.formData.id = event.id;
      }
      if (event && type === 'minSize') {
        this.formData.min_disk = event;
      }
      if (event && type === 'name') {
        this.formData.name = event;
      }
      // if (event && type === 'os' && event.from === 'windows') {
      if (event && type === 'os') {
        // console.log('os', event);
        // this.formData.os_type = this.osName.from;
        this.formData.os_type = event.from;

        this.formData.hw_scsi_model = 'virtio-scsi';
        this.formData.hw_qemu_guest_agent = 'yes';
        this.formData.hw_disk_bus = 'scsi';

        if (event.from === 'windows') {
          this.$emit('notready');
          this.formData.os_distro = event.os_distro;
          this.formData.hw_firmware_type = 'uefi';
          this.formData.hw_machine_type = 'q35';
        } else {
          delete this.formData.os_distro;
          delete this.formData.hw_firmware_type;
          delete this.formData.hw_machine_type;
        }

        // this.$emit('changeOs', {
        //   os_type: this.formData.os_type,
        //   os_distro: this.formData.os_distro,
        // });
      }

      // this.formData.min_disk = this.formData.sizeImage;
      // this.formData.os_distro = '';
      // this.formData.hw_firmware_type = 'uefi';
      // this.formData.hw_machine_type = 'q35';
      if (
        event &&
        event.type &&
        type === 'type' &&
        this.formatOfImage.map(x => x.type).includes(event.type)
      ) {
        this.formData.disk_format = event.type.toLowerCase();
        this.formData.type = event.type.toLowerCase();
      }
      // if (event && type === 'os' && event.from === 'windows') {
      //   this.formData.os_distro = 'windows';
      //   this.formData.hw_firmware_type = 'uefi';
      //   this.formData.hw_machine_type = 'q35';
      //   this.$emit('changeOs', {
      //     os_type: this.formData.os_type,
      //     os_distro: this.formData.os_distro,
      //   });
      // }
      if (event && event.type) {
        this.$emit('changeType', {
          disk_format: this.formData.disk_format,
        });
      }
      if (event && event.from) {
        this.formData.func = '';
        this.formData.source = '';
        this.formData.id = '';
      }
      // console.log('-------------------------', event, this.formData);
      // Object.assign(this.formData, event);
      if (this.formData.from === 'file') {
        if (
          this.formData.diskName &&
          this.formData.size &&
          this.formData.file &&
          this.formData.type.toLowerCase()
        ) {
          console.log('file');
          return this.$emit('change', {
            name: this.formData.diskName,
            min_disk: this.formData.sizeImage,
            size: +this.formData.size,
            disk_format: this.formData.disk_format,
            file: this.tempAttachments[0],
            os_type: this.formData.os_type,
            hw_scsi_model: this.formData.hw_scsi_model,
            hw_qemu_guest_agent: this.formData.hw_qemu_guest_agent,
            hw_disk_bus: this.formData.hw_disk_bus,
            os_distro: this.formData.os_distro,
            hw_firmware_type: this.formData.hw_firmware_type,
            hw_machine_type: this.formData.hw_machine_type,
          });
        } else return this.$emit('notready');
      } else if (this.formData.from === 'link') {
        if (
          this.formData.diskName &&
          this.formData.size &&
          this.formData.disk_format &&
          this.formData.link
        ) {
          // console.log('link');
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.formData.size,
            disk_format: this.formData.disk_format,
            link: this.formData.link,
            min_disk: this.formData.sizeImage,
            os_type: this.formData.os_type,
            hw_scsi_model: this.formData.hw_scsi_model,
            hw_qemu_guest_agent: this.formData.hw_qemu_guest_agent,
            hw_disk_bus: this.formData.hw_disk_bus,
            os_distro: this.formData.os_distro,
            hw_firmware_type: this.formData.hw_firmware_type,
            hw_machine_type: this.formData.hw_machine_type,
          });
        } else return this.$emit('notready');
      } else if (this.formData.from === 'disk') {
        // console.log('event', event);
        if (this.formData.diskName && this.formData.size && this.formData.id) {
          // console.log('disk');
          return this.$emit('change', {
            name: this.formData.diskName,
            size: this.formData.size,
            id: this.formData.id,
          });
        } else return this.$emit('notready');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "add_file": "Выбрать файл",
    "windowsInfo": "К сожалению, образ Windows нельзя добавить через личный кабинет. Его можно добавить только через cli, подробнее об этом Вы можете прочитать в инструкциях:",
    "cli": "Как настроить cli",
    "winImage": "Как установить образ Windows",
    "text_file_size": "Вы можете загрузить образ размером до 10ГБ.",
    "text_file_size_up": "Образы от 10ГБ до 128 ГБ можно загрузить через CLI.",
    "label": {
      "disk": "Источник образа:",
      "type": "Тип:",
      "description": "Описание",
      "image" : "Использовать диск как источник:",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снапшот как источник"
    },
    "disk": {
      "label": "Имя образа:",
      "placeholder": "Имя вашего образа",
      "description" : "Текстовое описание",
      "snap" : "Использовать диск как источник",
      "size": "Требование к размеру диска:",
      "link": "Ссылка на файл:",
      "url": "Адрес образа"
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "cost": "Расходы увеличатся на <b>%{msg}</b> в час.",
    "costLink": "Стоимость зависит от размера образа и рассчитывается по тарифу <b>%{msg} / ГБ</b> час",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-link{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
  }
  >>> .resize-link .l-col {
    padding-bottom: 0 !important;
  }
  &-column{
    //flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5rem;
    &-input {
      padding: 1.5rem 1.5rem;
    }
  }

  &-row{
    //flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 8px;
    +breakpoint(sm-and-up) {
      margin: 0 12px;
    }
    +breakpoint(md-and-up) {
    margin: 0 15px;
  }
    &-input {
      padding: 1.5rem 1.5rem;
    }
  }
}
</style>
